import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import { loadRewardSuppliers } from "src/shared/collections/BuiltRewardSupplierCollection";
import CampaignSummaryCollection from "src/shared/campaigns/CampaignSummaryCollection";
import CampaignCollection from "src/shared/campaigns/CampaignCollection";
import campaignControllersService from "src/shared/campaigns/campaign-controllers-service";

function groupBySupplier(campaignControllers, campaignCollection) {
    return _.reduce(
        campaignControllers,
        (suppliers, controllerDetails) => {
            const { campaignId } = controllerDetails.controllerModel;
            const rewardSupplierId =
                controllerDetails.action.reward_supplier_id;
            if (!suppliers[rewardSupplierId]) {
                suppliers[rewardSupplierId] = [];
            }
            suppliers[rewardSupplierId].push(
                campaignCollection.get(campaignId)
            );
            return suppliers;
        },
        {}
    );
}

function getUniqueCampaignsCollection(campaignsList1, campaignsList2) {
    const uniqueCampaigns = {};
    _.chain(campaignsList1)
        .union(campaignsList2)
        .each((collection) => {
            _.each(collection.models || collection, (campaign) => {
                const campaignId = campaign.get("campaign_id");
                uniqueCampaigns[campaignId] = campaign;
            });
        });
    const collection = new CampaignCollection(_.values(uniqueCampaigns));
    collection.sort();
    return collection;
}

const loadCampaignsBySupplier = _.memoize((rewardSupplierId) => {
    const deferred = $.Deferred();
    const campaigns = new CampaignCollection();
    $.when(
        campaigns.fetch({
            data: {
                reward_supplier_id: rewardSupplierId,
            },
        })
    )
        .done(() => {
            deferred.resolve(
                _.reject(campaigns.models, (campaign) => campaign.isGlobal())
            );
        })
        .fail(deferred.reject);
    return deferred;
});

const loadSuppliersFromCampaignControllers = _.memoize(() => {
    const deferred = $.Deferred();
    const summaries = new CampaignSummaryCollection();
    const actionTypes = [campaignControllersService.ACTION_TYPES.EARN_REWARD];
    $.when(summaries.fetch())
        .done(() => {
            const campaignIds = summaries.pluck("campaign_id");
            campaignControllersService
                .fetchBuiltControllersData(campaignIds, actionTypes)
                .done((campaignControllers) => {
                    const suppliersFromControllers = groupBySupplier(
                        campaignControllers,
                        summaries
                    );
                    deferred.resolve(suppliersFromControllers);
                });
        })
        .fail(deferred.reject);
    return deferred;
});

const loadAllCampaignsBySupplier = _.memoize((rewardSupplierId) => {
    const deferred = $.Deferred();
    loadSuppliersFromCampaignControllers()
        .done((suppliersFromCampaignControllers) => {
            loadCampaignsBySupplier(rewardSupplierId)
                .done((campaigns) => {
                    let newRewardCampaigns =
                        suppliersFromCampaignControllers[rewardSupplierId];
                    if (newRewardCampaigns) {
                        newRewardCampaigns = [newRewardCampaigns];
                    } else {
                        newRewardCampaigns = [];
                    }
                    const uniqueCampaigns = getUniqueCampaignsCollection(
                        [campaigns],
                        newRewardCampaigns
                    );
                    deferred.resolve(uniqueCampaigns);
                })
                .fail(deferred.reject);
        })
        .fail(deferred.reject);
    return deferred;
});

const loadCampaignsBySupplierType = _.memoize((rewardType) => {
    const deferred = $.Deferred();
    $.when(loadRewardSuppliers(), loadSuppliersFromCampaignControllers()).done(
        (suppliers, suppliersFromCampaignControllers) => {
            if (
                suppliers.isEmpty() &&
                _.isEmpty(suppliersFromCampaignControllers)
            ) {
                deferred.resolve(new Backbone.Collection());
                return;
            }
            const filtered = suppliers.where({
                reward_supplier_type: rewardType,
            });
            const rewardSupplierIds = _.pluck(filtered, "id");
            const newRewardCampaigns = _.reduce(
                suppliersFromCampaignControllers,
                (list, campaigns, rewardSupplierId) => {
                    if (_.contains(rewardSupplierIds, rewardSupplierId)) {
                        list.push(campaigns);
                    }
                    return list;
                },
                []
            );
            const campaignRequests = _.map(
                rewardSupplierIds,
                (rewardSupplierId) => loadCampaignsBySupplier(rewardSupplierId)
            );
            $.when.apply($, campaignRequests).done(function () {
                const campaignCollections =
                    Array.prototype.slice.call(arguments);
                const uniqueCampaignCollection = getUniqueCampaignsCollection(
                    campaignCollections,
                    newRewardCampaigns
                );
                deferred.resolve(uniqueCampaignCollection);
            });
        }
    );
    return deferred;
});

export default {
    loadCampaignsBySupplierType,
    loadAllCampaignsBySupplier,
};
