var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computed, toValue } from "vue";
import { useCampaignSummaryStore } from ".";
import { filter } from "lodash";
import { useAsyncPrimer } from "../common/async-primer";
import { useFuzzySearch } from "../common/fuzzy-search";
export const useCampaignSummaries = (parameters) => {
    const campaignSummaryStore = useCampaignSummaryStore();
    const primer = useAsyncPrimer(() => __awaiter(void 0, void 0, void 0, function* () {
        yield campaignSummaryStore.fetchCampaignSummaries(parameters);
    }), `useCampaignSummaries`);
    const campaignSummaries = computed(() => {
        return campaignSummaryStore.getCampaignSummaries(parameters === null || parameters === void 0 ? void 0 : parameters.include_archived);
    });
    return {
        primer,
        campaignSummaries,
    };
};
export const useAllCampaignSummaries = () => {
    const { primer, campaignSummaries } = useCampaignSummaries({
        include_archived: true,
    });
    return {
        primer,
        campaignSummaries,
    };
};
export const useMarketingCampaignSummaries = (parameters) => {
    const { primer, campaignSummaries } = useCampaignSummaries(parameters);
    const marketingCampaignSummaries = computed(() => {
        return filter(campaignSummaries.value, (campaign) => campaign.isMarketingCampaign());
    });
    return {
        primer,
        marketingCampaignSummaries,
    };
};
export const useCampaignSummary = (campaignId) => {
    const campaignSummaryStore = useCampaignSummaryStore();
    const { primer } = useCampaignSummaries();
    const campaignSummary = computed(() => {
        const campaignIdValue = toValue(campaignId);
        if (!campaignIdValue) {
            return undefined;
        }
        return campaignSummaryStore.getCampaignSummary(campaignIdValue);
    });
    return {
        primer,
        campaignSummary,
    };
};
const filterByState = (states, campaign) => {
    if (states.length === 0) {
        return true;
    }
    return states.includes(campaign.state);
};
const filterByDraft = (hasDraft, campaign) => {
    switch (hasDraft) {
        case "SHOW_ONLY":
            return campaign.hasDraft();
        case "EXCLUDE":
            return !campaign.hasDraft();
        default:
            return true;
    }
};
const filterByLocked = (locked, campaign) => {
    switch (locked) {
        case "SHOW_ONLY":
            return campaign.isLocked();
        case "EXCLUDE":
            return !campaign.isLocked();
        default:
            return true;
    }
};
const filterByTags = (tags, campaign) => {
    if (tags.length === 0) {
        return true;
    }
    return tags.some((tag) => campaign.tags.includes(tag));
};
export const useFilteredCampaignSummaries = (parameters) => {
    const { marketingCampaignSummaries: campaignSummaries, primer } = useMarketingCampaignSummaries({ include_archived: true });
    const filtered = computed(() => {
        const parametersValue = toValue(parameters);
        return campaignSummaries.value.filter((campaignSummary) => {
            return (!campaignSummary.isGlobal() &&
                filterByState(parametersValue.states, campaignSummary) &&
                filterByDraft(parametersValue.has_draft, campaignSummary) &&
                filterByLocked(parametersValue.is_locked, campaignSummary) &&
                filterByTags(parametersValue.having_any_tags, campaignSummary));
        });
    });
    const searchQuery = computed(() => {
        return toValue(parameters).search_query;
    });
    const fuzzySearched = useFuzzySearch(searchQuery, filtered, [
        "name",
        "description",
        "tags",
        "campaign_id",
        "program_label",
    ]);
    const filteredCampaignIds = computed(() => {
        return new Set(fuzzySearched.value.map((campaignSummary) => campaignSummary.campaign_id));
    });
    return {
        primer,
        campaignSummaries: fuzzySearched,
        filteredCampaignIds,
    };
};
export const useGlobalCampaignSummary = () => {
    const { primer, campaignSummaries } = useCampaignSummaries();
    const globalCampaign = computed(() => {
        return campaignSummaries.value.find((campaignSummary) => campaignSummary.program_type === "global-campaign" &&
            campaignSummary.name === "global");
    });
    return {
        primer,
        globalCampaign,
    };
};
