import Backbone from "backbone";
import _ from "underscore";
import $ from "jquery";
import Session from "Session";

const CAMPAIGN_INTENT_SUFFIX = "_campaign_intent";

const ClientPropertyModel = Backbone.Model.extend({
    idAttribute: "name",
    urlRoot: "/api/v2/properties",
    sync(method, model, options) {
        if (method === "update") {
            options.attrs = _.pick(this.attributes, "value");
        }
        return Backbone.sync.call(this, method, model, options);
    },
    parse(response) {
        if (response.name) {
            this._id = response.name;
        }
        return response;
    },
    isNew() {
        return _.isEmpty(this._id);
    },
});

const ClientPropertyCollection = Backbone.Collection.extend(
    {
        model: ClientPropertyModel,
        url: "/api/v2/properties",
        displayLaunchStatus(campaignId) {
            if (!campaignId) {
                throw new Error(
                    "A campaign_id is required to set the launch status display property"
                );
            }
            const campaignLaunchStatusDisplayPropertyName = `${campaignId}_display_launch_status`;
            return this.create(
                {
                    name: campaignLaunchStatusDisplayPropertyName,
                    value: true,
                },
                {
                    wait: true,
                }
            );
        },
    },
    {
        createCampaignIntent(campaignId) {
            const campaignIntentModel = new ClientPropertyModel({
                name: campaignId + CAMPAIGN_INTENT_SUFFIX,
            });
            return campaignIntentModel;
        },
        getCampaignIntent(campaignId) {
            return Session.getInstance().getProperty(
                campaignId + CAMPAIGN_INTENT_SUFFIX
            );
        },

        saveCampaignIntent(campaignId, intentValue) {
            if (!campaignId) {
                throw new Error(
                    "A campaign_id is required to set the campaign intent property"
                );
            }
            const campaignIntentPropertyName =
                campaignId + CAMPAIGN_INTENT_SUFFIX;
            const existingProperty = Session.getInstance().getProperty(
                campaignIntentPropertyName
            );
            if (_.isUndefined(existingProperty)) {
                const clientPropertyModel = new ClientPropertyModel({
                    name: campaignIntentPropertyName,
                    value: intentValue,
                });
                clientPropertyModel.isNewProperty = true;

                return clientPropertyModel.save();
            }

            return $.Deferred().resolve();
        },
    }
);

export default ClientPropertyCollection;
