import _ from "underscore";
import Backbone from "backbone";
import CampaignModel from "src/shared/campaigns/CampaignModel";

const STATE_ORDER = {
    LIVE: 0,
    NOT_LAUNCHED: 1,
    PAUSED: 2,
    STOPPED: 3,
    ENDED: 4,
};

const CampaignSummaryCollection = Backbone.Collection.extend({
    model: CampaignModel,

    url: "api:///v2/campaign-summaries",

    initialize(attributes, options) {
        this.filterModel = options?.filterModel;
    },

    filterByProgramLabel(programLabel) {
        if (programLabel) {
            return this.where({ program_label: programLabel });
        }
        return this;
    },

    parse(response) {
        return _.reject(
            response,
            (campaign) => campaign.program_type === "global-campaign"
        );
    },

    comparator(model1, model2) {
        return (
            STATE_ORDER[model1.get("state")] - STATE_ORDER[model2.get("state")]
        );
    },

    getCampaignSummariesbyProgramLable() {
        return Backbone.Collection.prototype.fetch
            .call(this, {
                data: {
                    program_label: this.filterModel.get("program_label"),
                },
            })
            .done((response) => {
                return _.reject(
                    response,
                    (campaign) => campaign.program_type === "global-campaign"
                );
            });
    },

    getMarketingCampaigns() {
        return _.filter(this.models, (model) => {
            return model.get("campaign_type") === "MARKETING";
        });
    },
});

CampaignSummaryCollection.create = function (filterModel) {
    return new CampaignSummaryCollection(
        {},
        {
            filterModel: filterModel,
        }
    );
};

export default CampaignSummaryCollection;
