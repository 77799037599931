import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import RewardSupplierTypeMetaData from "src/account-rewards/RewardSupplierTypeMetaData";
import RewardEvents from "src/shared/rewards/RewardEvents";
import RewardSupplierTypeSelectorView from "src/account-rewards/workflow/RewardSupplierTypeSelectorView";
import RewardSupplierModel from "src/account-rewards/RewardSupplierModel";
import SalesforceCouponModel from "src/account-rewards/SFCC/SalesforceCouponModel";
import CustomRewardModel from "src/account-rewards/custom-rewards/CustomRewardModel";
import PaypalPayoutModel from "src/account-rewards/paypal-payouts/PaypalPayoutModel";
import CouponModel from "src/account-rewards/coupons/CouponModel";
import queryStringService from "src/shared/query-string-service";
import SalesforceCouponModalView from "src/shared/salesforce-coupon/SalesforceCouponModalView";
import CustomRewardModalView from "src/shared/custom-reward/CustomRewardModalView";
import TangoRewardCreationWorkflow from "src/shared/gift-card/TangoRewardCreationWorkflow";
import RewardSupplierTangoModel from "src/account-rewards/gift-cards/RewardSupplierTangoModel";
import RewardSupplierTangoEditModal from "src/shared/gift-card/RewardSupplierTangoEditModal";
import TangoRewardCreationModalView from "src/shared/gift-card/TangoRewardCreationModalView";
import WorkflowLoader from "src/shared/WorkflowLoader";
import ClientTangoSettingsAccountCollection from "src/shared/gift-card/ClientTangoSettingsAccountCollection";
import TangoBrandCollection from "src/shared/gift-card/TangoBrandCollection";

const SPECIAL_CHARACTERS_REGEXP = /[^\w\d\s]/gi;
const GIFT_CARD_REWARD_TYPES = [
    "TANGO_V2",
    "US_GIFT_CARD",
    "INTERNATIONAL_GIFT_CARD",
];

const EVENTS = {
    COMPLETE: "complete",
    REMOVED: "removed",
};

export default {
    EVENTS,

    create(type, eventBus) {
        const $root = createRoot();
        const workflowData = new WorkflowData();
        const publicEventBus = eventBus || _.extend({}, Backbone.Events);
        const privateEventBus = _.extend({}, Backbone.Events);
        const workflow = new Workflow(workflowData, privateEventBus);
        const workflowLoader = new WorkflowLoader($root, "action-view", true);

        workflowData.setWorkflowLoader(workflowLoader);

        privateEventBus.on(RewardEvents.REWARD_SAVED, () => {
            workflowLoader.close();
            queryStringService.removeParameter("new-reward");
            publicEventBus.trigger(EVENTS.COMPLETE);
        });

        privateEventBus.on(RewardEvents.BACK, () => {
            workflowLoader.loadPrevious();
            queryStringService.removeParameter("new-reward");
        });

        if (type) {
            workflowData.setSelectedType(type);
            workflowLoader.load(workflow.rewardSupplierCreation);
        } else {
            privateEventBus.on(
                RewardEvents.REWARD_SUPPLIER_TYPE_SELECTED,
                (selectedType) => {
                    workflowData.setSelectedType(selectedType);
                    workflowLoader.load(workflow.rewardSupplierCreation);
                }
            );
            workflowLoader.load(workflow.rewardSupplierTypeSelection);
        }

        return publicEventBus;
    },

    edit(rewardSupplierId, rewardType, eventBus) {
        const $root = createRoot();
        const workflowData = new WorkflowData();
        const publicEventBus = eventBus || _.extend({}, Backbone.Events);
        const privateEventBus = _.extend({}, Backbone.Events);
        const workflow = new Workflow(workflowData, privateEventBus);
        const workflowLoader = new WorkflowLoader($root, "action-view", true);

        privateEventBus.on(RewardEvents.REWARD_SAVED, () => {
            $root.remove();
            workflowLoader.loadPrevious();
            publicEventBus.trigger(EVENTS.COMPLETE);
        });

        privateEventBus.on(RewardEvents.REWARD_REMOVED, () => {
            $root.remove();
            publicEventBus.trigger(EVENTS.REMOVED);
            if (!document.location.pathname.endsWith("account-rewards")) {
                document.location = "/account-rewards";
            }
        });

        privateEventBus.on(RewardEvents.BACK, () => {
            workflowLoader.loadPrevious();
        });

        loadModel(rewardSupplierId, rewardType).done((model) => {
            workflowData.setModel(model);
            workflowLoader.load(workflow.rewardSupplierEdition);
        });

        return publicEventBus;
    },
    async duplicate(model, eventBus) {
        const $root = createRoot();
        const workflowData = new WorkflowData();
        const publicEventBus = eventBus || _.extend({}, Backbone.Events);
        const privateEventBus = _.extend({}, Backbone.Events);
        const workflow = new Workflow(workflowData, privateEventBus);
        const workflowLoader = new WorkflowLoader($root, "action-view", true);

        if (GIFT_CARD_REWARD_TYPES.includes(model.reward_supplier_type)) {
            const { brand, account } = await loadTangoSettings(model);
            workflowData.setBrand(brand);
            workflowData.setAccount(account);
        }

        workflowData.setWorkflowLoader(workflowLoader);
        privateEventBus.on(RewardEvents.REWARD_SAVED, () => {
            workflowLoader.close();
            queryStringService.removeParameter("new-reward");
            publicEventBus.trigger(EVENTS.COMPLETE);
        });

        privateEventBus.on(RewardEvents.BACK, () => {
            workflowLoader.loadPrevious();
            queryStringService.removeParameter("new-reward");
        });

        privateEventBus.on(RewardEvents.CLOSE, () => {
            workflowLoader.close();
            publicEventBus.trigger(EVENTS.COMPLETE);
        });

        workflowData.setDuplicatedModel(model);
        workflowLoader.load(workflow.rewardSupplierDuplicate);
        return publicEventBus;
    },
};

function WorkflowData() {
    this.setSelectedType = function (selectedType) {
        this.selectedType = selectedType;
    };
    this.getSelectedType = function () {
        return this.selectedType;
    };

    this.setBrand = function (brand) {
        this.brand = brand;
    };

    this.getBrand = function () {
        return this.brand;
    };

    this.setModel = function (model) {
        this.model = model;
    };
    this.getModel = function () {
        return this.model;
    };

    this.setAccount = function (account) {
        this.account = account;
    };

    this.getAccount = function () {
        return this.account;
    };

    this.setWorkflowLoader = function (workflowLoader) {
        this.workflowLoader = workflowLoader;
    };
    this.getWorkflowLoader = function () {
        return this.workflowLoader;
    };

    this.setDuplicatedModel = function (duplicatedModel) {
        this.duplicatedModel = duplicatedModel;
    };

    this.getDuplicatedModel = function () {
        return this.duplicatedModel;
    };
}

function Workflow(workflowData, privateEventBus) {
    this.rewardSupplierTypeSelection = {
        createView() {
            return RewardSupplierTypeSelectorView.create(privateEventBus);
        },
    };

    this.rewardSupplierCreation = {
        createView() {
            const selectedType = workflowData.getSelectedType();
            const workflowLoader = workflowData.getWorkflowLoader();
            const tags = [];

            switch (selectedType) {
                case "SFCC":
                case "SALESFORCE_COUPON": {
                    return SalesforceCouponModalView.create(
                        new SalesforceCouponModel(),
                        privateEventBus
                    );
                }

                case "TANGO_V2":
                case "US_GIFT_CARD":
                case "INTERNATIONAL_GIFT_CARD": {
                    const filterFunction = getTangoFilterFunction(selectedType);
                    return TangoRewardCreationWorkflow.create(
                        filterFunction,
                        workflowLoader,
                        privateEventBus
                    );
                }

                case "CUSTOM_REWARD": {
                    return CustomRewardModalView.create(
                        new CustomRewardModel(null, {
                            display_type: selectedType,
                            tags,
                        }),
                        privateEventBus
                    );
                }

                case "MANUAL_COUPON": {
                    return RewardSupplierTypeMetaData.getCreateViewClass(
                        selectedType
                    ).create(new CouponModel(), privateEventBus);
                }

                case "PAYPAL_PAYOUTS": {
                    return RewardSupplierTypeMetaData.getCreateViewClass(
                        selectedType
                    ).create(new PaypalPayoutModel(), privateEventBus);
                }

                default: {
                    document.location = "/account-rewards";
                }
            }
        },
    };

    this.rewardSupplierEdition = {
        createView() {
            const model = workflowData.getModel();
            const rewardType = model.get("reward_supplier_type") || model.type;

            switch (rewardType) {
                case "TANGO_V2":
                    return RewardSupplierTangoEditModal.create(
                        model,
                        privateEventBus
                    );

                case "SALESFORCE_COUPON":
                case "CUSTOM_REWARD":
                case "MANUAL_COUPON":
                case "ACCOUNT_CREDIT":
                case "PAYPAL_PAYOUTS":
                case "COUPON":
                    return RewardSupplierTypeMetaData.getEditViewClass(
                        rewardType
                    ).create(model, privateEventBus);

                default:
                    document.location = "/account-rewards";
            }
        },
    };

    this.rewardSupplierDuplicate = {
        createView() {
            const model = workflowData.getDuplicatedModel();
            const account = workflowData.getAccount();
            const brand = workflowData.getBrand();
            model.isDuplicated = true;
            const rewardType = model.reward_supplier_type || model.type;
            switch (rewardType) {
                case "SFCC":
                case "SALESFORCE_COUPON":
                    model.name = `${model.name}_copy`;
                    return SalesforceCouponModalView.create(
                        new SalesforceCouponModel(model),
                        privateEventBus
                    );

                case "TANGO_V2":
                case "US_GIFT_CARD":
                case "INTERNATIONAL_GIFT_CARD":
                    return TangoRewardCreationModalView.create(
                        privateEventBus,
                        brand,
                        account,
                        model
                    );
                case "CUSTOM_REWARD":
                    model.name = `${model.name}_copy`;
                    return CustomRewardModalView.create(
                        new CustomRewardModel(model),
                        privateEventBus
                    );

                case "MANUAL_COUPON":
                    model.name = `${model.name}_copy`;
                    return RewardSupplierTypeMetaData.getCreateViewClass(
                        rewardType
                    ).create(new CouponModel(model), privateEventBus);

                case "PAYPAL_PAYOUTS":
                    model.name = `${model.name}_copy`;
                    return RewardSupplierTypeMetaData.getCreateViewClass(
                        rewardType
                    ).create(new PaypalPayoutModel(model), privateEventBus);

                default:
                    document.location = "/account-rewards";
            }
        },
    };
}

function getTangoFilterFunction(rewardType) {
    switch (rewardType) {
        case "US_GIFT_CARD":
            return function (model) {
                return _.filter(model.items, isUSD).length > 0;
            };

        case "INTERNATIONAL_GIFT_CARD":
            return function (model) {
                return _.filter(model.items, isUSD).length === 0;
            };
    }

    return function () {
        return true;
    };
}

function isUSD(item) {
    return item.currency_code === "USD";
}

function createRoot() {
    const $root = $(document.createElement("DIV"));
    $(document.body).append($root);
    return $root;
}

function loadModel(rewardSupplierId, rewardType) {
    const deferred = $.Deferred();
    let model;

    switch (rewardType) {
        case RewardSupplierModel.REWARD_SUPPLIER_TYPES.CUSTOM_REWARD:
            model = new CustomRewardModel({
                id: rewardSupplierId,
            });
            break;
        case RewardSupplierModel.REWARD_SUPPLIER_TYPES.TANGO_V2:
            model = new RewardSupplierTangoModel({
                id: rewardSupplierId,
            });
            break;
        case RewardSupplierModel.REWARD_SUPPLIER_TYPES.MANUAL_COUPON:
            model = new CouponModel({
                id: rewardSupplierId,
            });
            break;
        case RewardSupplierModel.REWARD_SUPPLIER_TYPES.SALESFORCE_COUPON:
            model = new SalesforceCouponModel({
                id: rewardSupplierId,
            });
            break;
        case RewardSupplierModel.REWARD_SUPPLIER_TYPES.PAYPAL_PAYOUTS:
            model = new PaypalPayoutModel({
                id: rewardSupplierId,
            });
            break;
        default:
            model = new RewardSupplierModel({
                id: rewardSupplierId,
            });
    }

    $.when(model.fetch())
        .done(() => {
            deferred.resolve(model);
        })
        .fail(deferred.reject);
    return deferred;
}

function removeSpecialSymbolsFromString(string) {
    return string.replace(SPECIAL_CHARACTERS_REGEXP, "");
}

function getOrCreateFirstAccount() {
    const deferred = $.Deferred();
    const accountsCollection = new ClientTangoSettingsAccountCollection();
    $.when(accountsCollection.fetch()).done(() => {
        if (accountsCollection.length === 0) {
            accountsCollection
                .create({
                    funds_amount_warn_limit: "2500",
                })
                .done((account) => {
                    deferred.resolve(account);
                });
        } else {
            deferred.resolve(accountsCollection.first());
        }
    });
    return deferred;
}

function loadTangoSettings(duplicatedRewardSupplier) {
    const deferred = $.Deferred();
    const tangoBrandCollection = new TangoBrandCollection();
    $.when(getOrCreateFirstAccount()).done((account) => {
        $.when(tangoBrandCollection.fetch()).done(() => {
            const duplicatedRewardSupplierName = duplicatedRewardSupplier.name;
            const tangoBrandModels = _.filter(
                tangoBrandCollection.models,
                (brandModel) =>
                    _.some(brandModel.get("items"), (item) => {
                        return (
                            removeSpecialSymbolsFromString(item.reward_name) ===
                            removeSpecialSymbolsFromString(
                                duplicatedRewardSupplierName
                            )
                        );
                    })
            );
            deferred.resolve({
                brand: _.first(tangoBrandModels),
                account,
            });
        });
    });
    return deferred;
}
