import Backbone from "backbone";
import _ from "underscore";
import ControllerTriggerModel from "src/labs/campaign-controllers/ControllerTriggerModel";
import ControllerActionModel from "src/shared/models/ControllerActionModel";
import Strings from "src/shared/Strings";
import evaluatableFieldService from "src/shared/ui-components/evaluatable-field/evaluatable-field-service";

export const parseUniquePartnerKeyName = (controller) => {
    const uniquePartnerEventKeyName = _.findWhere(controller?.data || [], {
        key_type: "UNIQUE_PARTNER_EVENT_KEY",
    })?.name;

    if (uniquePartnerEventKeyName) {
        return uniquePartnerEventKeyName;
    }

    const partnerEventKeyName = _.findWhere(controller?.data || [], {
        key_type: "PARTNER_EVENT_KEY",
    })?.name;

    return partnerEventKeyName;
};

const ATTRIBUTES_TO_SAVE = [
    "enabled",
    "enabled_on_states",
    "data",
    "name",
    "aliases",
    "scope",
    "journey_names",
    "selectors",
    "component_ids",
    "type",
    "send_policy",
];
const NAME_PREFIX = "COPY_OF_";
const ControllerModel = Backbone.Model.extend({
    idAttribute: "id",

    defaults: {
        enabled: true,
        enabled_on_states: [],
        journey_names: [],
        selectors: [],
        triggers: [],
        actions: [],
        aliases: [],
        component_ids: [],
        data: [],
        type: "CONTROLLER",
        send_policy: "ALL",
    },

    initialize(attributes, options) {
        this.campaignId =
            (options && options.campaignId) || this.collection.campaignId;
        this.supported_evaluatable_type = "buildtime";
    },

    url() {
        let path = `/api/v2/campaigns/${this.campaignId}/controllers`;
        if (this.id) {
            path += `/${this.id}`;
        }
        return path;
    },

    sync(method, model, options) {
        if (method === "create" || method === "update") {
            if (!evaluatableFieldService.isEvaluatable(this.attributes.name)) {
                const name = Strings.encodeSnakeCase(this.attributes.name);
                this.attributes.name = name;
            }
            options.attrs = _.pick(this.attributes, ATTRIBUTES_TO_SAVE);
        }
        return Backbone.sync.call(this, method, model, options);
    },

    getCampaignId() {
        return this.campaignId;
    },
});

ControllerModel.create = function (campaignId) {
    return new ControllerModel(null, { campaignId });
};

ControllerModel.duplicate = function (controllerModel) {
    const templatizedCollection =
        controllerModel.collection.getTemplatizedCollection();
    const templatizedModel = templatizedCollection.get(
        controllerModel.get("id")
    );
    const controllerAttributes = templatizedModel.attributes;
    const name = evaluatableFieldService.isEvaluatable(
        controllerAttributes.name
    )
        ? controllerAttributes.name
        : NAME_PREFIX + Strings.encodeSnakeCase(controllerModel.get("name"));
    const actions = _.map(
        controllerAttributes.actions,
        (action) => new ControllerActionModel(action).duplicate().attributes
    );
    const triggers = _.map(
        controllerAttributes.triggers,
        (trigger) => new ControllerTriggerModel(trigger).duplicate().attributes
    );
    const newControllerAttributes = _.chain(controllerAttributes)
        .pick(ATTRIBUTES_TO_SAVE)
        .extend({
            name,
            actions,
            triggers,
        })
        .value();
    return new ControllerModel(newControllerAttributes, {
        campaignId: controllerModel.campaignId,
    });
};

export default ControllerModel;
