import Backbone from "backbone";
import _ from "underscore";
import CampaignModel from "src/shared/campaigns/CampaignModel";

const STATE_ORDER = {
    LIVE: 0,
    NOT_LAUNCHED: 1,
    PAUSED: 2,
    STOPPED: 3,
    ENDED: 4,
};
const STATES = {
    NOT_LAUNCHED: "NOT_LAUNCHED",
    LIVE: "LIVE",
};

const CampaignCollection = Backbone.Collection.extend(
    {
        url: "/api/v2/campaigns",
        model: CampaignModel,
        comparator(model1, model2) {
            return (
                STATE_ORDER[model1.get("state")] -
                STATE_ORDER[model2.get("state")]
            );
        },
        getMarketingCampaigns() {
            return _.filter(this.sort().toJSON(), (campaign) => {
                return campaign.campaign_type === "MARKETING";
            });
        },
    },
    {
        STATES,
    }
);

CampaignCollection.create = function () {
    return new CampaignCollection();
};

export default CampaignCollection;
